<template>
  <div class="ui-input-editor">
    <ui-field-editor
      v-model="inputProps"
      :show-label="value.type != 'checkbox'"
      @input="emitInput"
    >
      <template v-if="value.type == 'textarea'">
        <textarea
          style="width: 99%; pointer-events: none"
          class="ui-native"
          v-bind="$attrs"
        ></textarea>
      </template>
      <template v-else-if="value.type == 'select'">
        <ui-select-editor
          v-model="inputProps"
          @input="emitInput"
        ></ui-select-editor>
      </template>
      <template v-else-if="value.type == 'checkbox'">
        <ui-item
          class="ui-checkbox-editor"
          icon="mdi:checkbox-blank-outline"
          icon-color="var(--ui-color-primary)"
        >
          <input
            style="width: 100%; font: inherit; border: 0; background: transparent"
            placeholder="Agregar texto"
            v-model="inputProps.label"
            @input="emitInput"
          />
        </ui-item>
      </template>
      <template v-else>
        <input
          class="ui-native"
          :type="value.type"
          v-bind="$attrs"
          style="pointer-events: none"
        />
      </template>
    </ui-field-editor>
  </div>
</template>

<script>
import { UiItem } from '@/modules/ui/components';
import UiFieldEditor from '@/modules/ui/components/UiFieldEditor/UiFieldEditor.vue';
import UiSelectEditor from '@/modules/ui/components/UiSelectEditor/UiSelectEditor.vue';

export default {
  name: 'ui-input-editor',
  components: {
    UiItem,
    UiFieldEditor,
    UiSelectEditor,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      inputProps: {},
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.inputProps = JSON.parse(JSON.stringify(newValue));
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.inputProps)));
    },
  },
};
</script>